<template>
  <div
    class="vue-app">
    <router-view
      :apiToken="apiToken"
      :apiUrl="apiUrl"
      :apiUrlV2="apiUrlV2"
      :translations="JSON.parse(translations)"
      :available_sortings="available_sortings"
      :lang="lang"
      :sortings="sorting"
    />
  </div>
</template>

<script>
export default {
  name: "app",
  props: {
    apiToken: {
      type: String,
      required: true,
    },
    apiUrl: {
      type: String,
      required: true,
    },
    apiUrlV2: {
      type: String,
      required: true,
    },
    translations: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    available_sortings: {
      type: Array,
      required: false,
    },
    sorting: {
      type: Object,
      required: false,
    }
  },
};
</script>

<style lang="less">

</style>
